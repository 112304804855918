import * as React from "react";

const DownArrow = (props) => (
  <svg
    width={23}
    height={12}
    fill="none"
    stroke="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M23 1h-3.025l-8.448 10L3.08 1H0" />
  </svg>
);

export default DownArrow;
