import React from "react";
import Box from "./box";
import styled from "styled-components";

const StyledLi = styled("li")`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 1em 0;
  flex-wrap: wrap;
`;

const StyledH2 = styled(Box)`
  font-family: "PPEiko";
  font-size: 20px;
`;

const Button = styled(Box)`
  display: inline-block;
  border-radius: 2em;
  padding: 0.5em 1em;
  background: #676859;
  color: currentColor;
  text-decoration: none;
`;

const Spectacle = ({
  data: { date, title, endroit, description, time, button_text, link },
}) => {
  return (
    <StyledLi>
      <StyledH2 as="h2" width={[1, 1 / 5]}>
        {title}
      </StyledH2>
      <Box width={[1, 2 / 5]}>
        <Box>{endroit}</Box>
        <Box>{description}</Box>
      </Box>
      <Box width={[1, 1 / 5]}>
        <Box>{date}</Box>
        <Box>{time}</Box>
      </Box>
      <Box width={[1, 1 / 5]} textAlign="right" alignSelf="flex-start">
        <Button as="a" href={link}>
          {button_text} &rarr;
        </Button>
      </Box>
    </StyledLi>
  );
};

export default Spectacle;
