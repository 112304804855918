import React, { useState } from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import Wrapper from "../components/wrapper";
import Flex from "../components/flex";
import Box from "../components/box";
import SpectaclesRow from "../components/spectaclesrow";
import { Router, Location } from "@reach/router";
import { AnimatePresence } from "framer-motion";
import PageH1 from "../components/pageh1";

import { Link } from "gatsby";
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next";
import Layout from "../components/layout";
import styled from "styled-components";
import DownArrow from "../images/downarrow";

const FramerRouter = ({ children }) => (
  <Location>
    {({ location }) => (
      <div style={{ position: "relative" }}>
        <AnimatePresence exitBeforeEnter>
          <Router key={location.key} location={location} primary={false}>
            {children}
          </Router>
        </AnimatePresence>
      </div>
    )}
  </Location>
);

const Home = ({ spectacles }) => {
  const { t } = useTranslation();
  return (
    <SpectaclesRow
      spectacles={spectacles}
      empty={t("empty", { ns: "spectacles" })}
    />
  );
};

const FilteredSpectacles = ({ artist, spectacles, empty }) => {
  const { t } = useTranslation();
  const filtered = spectacles
    ? Object.values(spectacles).filter((value) => value.artist === artist)
    : null;
  return (
    <Box>
      <SpectaclesRow
        spectacles={filtered}
        empty={t("empty", { ns: "spectacles" })}
      />
    </Box>
  );
};

const Select = styled(Box)`
  cursor: pointer;
  background: #353d38;
  padding: 0.5em 1em;
  min-width: 130px;
  display: inline-block;
  position: relative;
  &:hover {
    background: #232925;
  }
`;
const Options = styled(Box)`
  position: absolute;
  top: 100%;
  right: 0;
  left: auto;
  text-align: right;
  z-index: 999;
`;

const StyledLink = styled(Link)`
  display: inline-block;
  width: 100%;
  text-decoration: none;
  box-sizing: border-box;
  padding: 0.5em 1em;
  color: #68695c;
  background: #232925;
  &:hover {
    color: white;
    text-decoration: none;
  }
`;

const SpectaclesPage = ({ data }) => {
  const { t, language } = useI18next();
  const spectacles = t("spectacles", { ns: "spectacles" });
  const artists = t("artistes", { ns: "artistes" });

  const [show, setShow] = useState(false);

  const handleBlur = (e) => {
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setShow(false);
    }
  };

  return (
    <Layout bg="#44524B">
      <Helmet>
        <title>{t("title")} - La Maison Mère</title>
      </Helmet>
      <Flex alignItems="center" color="white">
        <Wrapper>
          <Flex justifyContent="space-between" alignItems="center" my={5}>
            <PageH1>{t("title", { ns: "spectacles" })}</PageH1>
            <Box>
              <Box>
                <Select
                  onClick={() => setShow(!show)}
                  onBlur={(e) => handleBlur(e)}
                  tabIndex="1"
                >
                  <Flex justifyContent="space-between" alignItems="center">
                    <Box>{language === "en" ? "Filtrer" : "Filter"}</Box>
                    <Box ml={2}>
                      <DownArrow />
                    </Box>
                  </Flex>
                  {show && (
                    <Options>
                      <StyledLink
                        to={
                          language === "en" ? "/en/spectacles" : "/spectacles"
                        }
                      >
                        Tous
                      </StyledLink>
                      {Object.keys(artists).map((obj, i) => (
                        <StyledLink
                          to={
                            language === "en"
                              ? `/en/spectacles/${artists[obj].slug}`
                              : `/spectacles/${artists[obj].slug}`
                          }
                          key={i}
                        >
                          {artists[obj].name}
                        </StyledLink>
                      ))}
                    </Options>
                  )}
                </Select>
              </Box>
            </Box>
          </Flex>

          <FramerRouter>
            <Home
              path={language === "en" ? "/en/spectacles" : "/spectacles"}
              spectacles={typeof spectacles === "object" ? spectacles : null}
              title={t("title")}
            />
            <FilteredSpectacles
              spectacles={typeof spectacles === "object" ? spectacles : null}
              path={
                language === "en"
                  ? "/en/spectacles/:artist"
                  : "/spectacles/:artist"
              }
            />
          </FramerRouter>
        </Wrapper>
      </Flex>
    </Layout>
  );
};

export default SpectaclesPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["spectacles", "artistes"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
