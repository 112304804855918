import React from "react";
import Box from "./box";
import styled from "styled-components";
import Spectacle from "../components/spectacle";
import { motion } from "framer-motion";

const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.125,
    },
  },
};

const listItem = {
  hidden: { opacity: 0, y: 10 },
  show: { opacity: 1, y: 0 },
};

const SpectaclesRow = ({ spectacles, empty }) => {
  return (
    <motion.div
      variants={container}
      initial="hidden"
      animate="show"
      style={{ borderTop: "1px solid #68685d" }}
    >
      {spectacles && spectacles.length > 0 ? (
        Object.keys(spectacles).map((obj, i) => {
          return (
            <motion.div key={i} variants={listItem}>
              <Box style={{ borderBottom: "1px solid #68685d" }}>
                <Spectacle data={spectacles[obj]} />
              </Box>
            </motion.div>
          );
        })
      ) : (
        <Box style={{ borderBottom: "1px solid #68685d", padding: "1em 0" }}>
          {empty}
        </Box>
      )}
    </motion.div>
  );
};

export default SpectaclesRow;
